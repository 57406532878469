@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*=========================================================================================================================*/
/*=========================================================================================================================*/
/*=========================================================================================================================*/
.flex-container {
  height: 100vh;
}
.flex-child-no-shrink {
  flex-shrink: 0;
}


//*========== CABECERA ==========*/
img.logo {
    width: 287px;
    height: 150px;
    margin: 50px auto 30px;
}
img.logo-res {
    width: 115px;
    height: 60px;
    margin: 5px 10px 5px;
}
#mmenu-wrapper {
    border-bottom: 6px solid $primary-color;
}
.menu > li > a {
     text-transform: uppercase;
}


.dropdown.menu li {
    /*background-color: aquamarine;*/
    padding: 0.2rem 0.4rem 0.4rem;
}
.dropdown.menu a {
    padding: 0.5rem 0.6rem 0.3rem;
    /*background-color: red;*/
    border: 2px solid $white;
    border-radius: 6px;
}
.menu .active > a, .dropdown.menu a:hover {
    border:2px solid $primary-color;
    color: $secondary-color;
}
.menu .active > a:hover {
    background-color: $primary-color;
    color: $white;
}
.dropdown.menu li li {
    padding: 0;
}
.dropdown.menu li li a {
    padding: 0.7rem 1rem 0.7rem;
    background-color: $primary-color;
    border: none;
    border-radius: 0;
    color: $white;
}
.dropdown.menu li li a:hover {
    border: none;
    background-color: darken($primary-color,10%);
    color:$white;
}


.title-bar {
    justify-content: space-between;
}
.title-bar .menu-icon {
    margin-right: 15px;
}
.menu-icon {
    width: 36px;
    height: 28px;
}
.menu-icon::after {
    height: 4px;
    /*background: #fefefe;*/
    box-shadow: 0 12px 0 $primary-color, 0 24px 0 $primary-color;
    transition: all ease 500ms;
}
.menu-icon:hover::after {
    /*background: #cacaca;*/
    box-shadow: 0 12px 0 $secondary-color, 0 24px 0 $secondary-color;
}
.is-dropdown-submenu {
    border-bottom: 1px solid $primary-color;
    border-left: 1px solid $primary-color;
    border-right: 1px solid $primary-color;
    /*background: #fefefe;*/
}
/*=========== SLIDER ===========*/
.orbit-bullets {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    bottom: -50px;
    background-color: transparent;
}
.orbit-bullets button {
    border: 2px solid white;
}
.orbit-caption {
    position: absolute;
    bottom: 50px;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
}
.orbit-caption a {
    color: #fff;
}
.orbit-caption h3 {
    font-size: 4rem;
}
.orbit-caption h3 a span {
    color: $primary-color;
    font-weight: 600;
}

/*========= TIPOGRAFIA =========*/
h1, h2 {
    /*text-transform: uppercase;*/
    color: $primary-color;
}
h1 {
    margin-bottom: 0.5em;
}
h2.sq-title {
    font-size: 1.3rem;
    font-weight: 400;
}
h3 {
    color: $primary-color;
}
.team-member h2 {
    text-transform: none;
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
}
.team-member h3 {
    font-size: 1.3rem;
    color: $secondary-color;
    margin-bottom: 0.2rem;
}
.team-member h4 {
    font-size: 1rem;
    color: $darker-gray;
    font-weight: 400;
}
a.link-toggle {
    padding: 4px 5px;
    border: 1px solid #aa55a6;
    font-weight: 600;
}
blockquote {
    font-size: 200%;
}
.color {
    color: $primary-color;
    font-weight: 600;
}

/*======= LISTA ORDENADA =======*/
ol {
	counter-reset:li; /* Initiate a counter */
	list-style:none;
    margin-left: 0;
    padding-left: 0;
}
ol > li {
    margin: 0 0 0.5rem 3rem;
    display: block;
    position: relative;
}
ol > li:before {
    @include vertical-center;
    position:absolute;
    content:counter(li);
	counter-increment:li;
    /*margin-left: -3rem;*/
    /*display: inline-block;
    width: 2rem;*/
    left: -2.5rem;
    font-size: 2rem;
    font-weight: 600;
    color: $primary-color;
}

/*========= CONTENIDOS =========*/
img.main-img {
    width: 100%;
    height: auto;
}
.himage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 300px;
}
.color-wrap {
    background-color: $primary-color;
    color: $white;
}
a.csquare {
    /*width: 100%;*/
    border: 2px solid $white;
    background-color: $primary-color;
    color: $white;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
    padding: 60px 0;
    transition: all ease 500ms;
}
a.csquare:hover {
    background-color: darken($primary-color,10%);
}
.reveal, .reveal h2, .reveal h3 {
    color: $white;
}
.reveal ol > li:before {
    color: $medium-gray;
}
.reveal .full-h {
    min-height: calc(100vh - 32px);
}
.reveal .close-button {
    color: $white;
}
.reveal .close-button:hover {
    color: $black;
}

#preocupaciones .accordion-title {
    padding: 1.2rem 2rem 1rem;
    border: 1px solid $white;
    border-bottom: 0;
    background-color: $primary-color;
    color: $white;
}
#preocupaciones .accordion-title h2 {
    font-size: 1.8rem;
    color: $white;
}
#preocupaciones .accordion-item:first-child > :first-child {
    border-radius: none;
}
#preocupaciones :last-child > .accordion-content:last-child {
    border-bottom: none;
}
#preocupaciones .accordion-item:last-child > :last-child {
    border-radius: none;
}
#preocupaciones .accordion-content {
    padding: 1.5rem 2rem 1rem;
    border: 1px solid $white;
    border-bottom: 0;
    background-color: $white;
}

/*====== IMAGES CONTAINER ======*/
a.servicios {
    display: block;
    position: relative;
}
.servicios img {
    width: 100%;
    height: auto;
}
.servicios h2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
    background-color: rgba($white,0.5);
    margin-bottom: 1rem;
    transition: all ease 500ms;
}
a.servicios:hover h2 {
    height: 0;
    padding-bottom: 55%;
    padding-top: 45%;
    /*display: table-cell;*/
    vertical-align: middle;
    background-color: rgba($primary-color,0.8);
    margin-bottom: 0;
    color: $white;
}

/*======= FOUNDATION FIX =======*/
.menu-centered {
    line-height: 0;
}
nav .sticky {
    z-index: 999;
}

/*=========== EXTRAS ===========*/
.full-width {
    width: 100%;
}
.button.hollow {
    border-width: 2px;
}
.button.hollow.thin {
    border-width: 1px;
}
/*========== FOOTER ============*/
footer {
    color: white;
}
footer h1, footer h2, footer h3, footer h4, footer h5 {
    color: white;
    margin: 0;
    padding: 0;
}
.logo-pie {
    width: 268px;
    height: 140px;
    margin: 40px auto 30px;
}
.pie-1 {
    background-color: $dark-gray;
}
.pie-2 {
    background-color: $darker-gray;
    padding: 30px 0 10px;
}
.pie-3 {
    background-color: $primary-color;
    border-top: 8px solid white;
    padding: 15px 0 10px;
}
.pie-3 a {
    color:#fff;
}
.pie-4 {
    background-color: $white;
    color: $darker-gray;
    padding: 10px 0 8px;
    font-size: 0.85rem;
}
.pie-4 a {
    color: $darker-gray;
}
.pie-4 a:hover {
    color: $primary-color;
}

/*========= ESPACIADO ==========*/
.pad-20 {
    padding: 20px;
}

@mixin offset-margin($margin, $value){
  margin-#{$margin}: $value;
}
@for $i from 1 through 20 {
  @each $margin in top, left, bottom, right {

    .offset-#{$margin}-#{$i}{
      @include offset-margin($margin, 0.5em * $i)
    }

  }
}

@mixin padd-padding($padding, $value){
  padding-#{$padding}: $value;
}
@for $i from 1 through 20 {
  @each $padding in top, left, bottom, right {

    .padd-#{$padding}-#{$i}{
      @include padd-padding($padding, 0.5em * $i)
    }

  }
}



/*=========== NO-JS ============*/
.no-js {
  @include breakpoint(small) {
    .top-bar {
      display: none;
    }
  }
  @include breakpoint(large only) {
    .title-bar {
      display: none;
    }
  }
}





@media screen and (max-width: 1023px) {
    img.logo { display: none; visibility: hidden; }
}
@media screen and (max-width: 639px) {
    /*.small-order-1 { order:1; }
    .small-order-2 { order:2; }*/
}
@media screen and (min-width: 1024px) and (min-height: 600px) {
    body {
        background-color: $dark-gray;
    }
    .area-menu, .area-content {
        background-color: transparent;
        z-index: 1;
    }
    .area-menu, .main-content {
        background-color: $white;
    }
    .sp-ft {
        height: 520px;
        pointer-events: none;
    }
    .area-content {
        margin: 0;
        padding: 0;
        pointer-events: none;
    }
    .area-footer {
        position: fixed;
        bottom: 0;
        width: 100%;  
        z-index: 0;
    }
}
